import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.css']
})
export class DropZoneComponent implements OnInit {

  public isFileSet:boolean = false;
  public fileName:string = '';

  constructor() { }

  @Output() onFileChosen: EventEmitter<File> = new EventEmitter();

  ngOnInit(): void {
  }

  onChooseFile(files: File[]) {
    this.onFileChosen.emit(files[0]);
    this.fileName = files[0].name;
    this.isFileSet = true;
  }

  onRemoveFile() {
    this.onFileChosen.emit(undefined);
    this.fileName = undefined;
    this.isFileSet = false;
  }
}
