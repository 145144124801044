export class RegisterMemberPermission{
    public memberId:number;
    public readers:ReaderPermission[];
    public canReadConsolidated:boolean;

    constructor(readers:ReaderPermission[] = null, memberId:number = null, canReadConsolidated:boolean = null) 
    {
        this.memberId = memberId;
        this.readers = readers;
        this.canReadConsolidated = canReadConsolidated;
    }
}

export class ReaderPermission {
    public readerMemberId:number;
    public value:boolean;

    constructor(readerMemberId:number, value:boolean) 
    {
        this.readerMemberId = readerMemberId;
        this.value = value;
    }
}