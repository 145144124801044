<app-lkwd-header headerName="Dashboard">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <mat-card style="background: #001d2b;">
          <app-lkwd-chart-header (changeDataRange)="onChangeDataRangeLeftChart($event)" [selectOptions]="firstChartOptions" title="Uso por device em" (export)="exportData(false)"></app-lkwd-chart-header>      
          <div style="margin: 60px 40px;  height: 400px;" >
              <app-lkwd-doughnut *ngIf="!leftChartloading" [chartData]="leftChartData" ></app-lkwd-doughnut>
              <ngx-loading [show]="leftChartloading"
                [config]="loadingConfig"></ngx-loading>         
          </div>    
        </mat-card>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <mat-card style="background: #001d2b">
        <app-lkwd-chart-header (changeDataRange)="onChangeDataRangeRightChart($event)" [selectOptions]="firstChartOptions" title="Acessos em" (export)="exportData(true)"></app-lkwd-chart-header>      
        <div style="margin: 60px 40px; height: 400px; " >
          <app-lkwd-line-chart *ngIf="!rightChartloading" [chartData]="rightChartData"></app-lkwd-line-chart>
          <ngx-loading [show]="rightChartloading"
            [config]="loadingConfig"></ngx-loading>          
        </div>
      </mat-card>
      </div>
    </div>
</app-lkwd-header>
