import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lkwd-button',
  templateUrl: './lkwd-button.component.html',
  styles: 
  [
    `
    .lkwd-large-btn {
      width: 397px;
      height: 50px;
      padding: 5px 3px;
      outline: none;
      font-weight: bold;
    }

    .lkwd-medium-btn {
      width: 190px;
      padding: 5px 3px;
      outline: none;
      font-weight: bold;
    }

    .lkwd-small-btn {
      width: 126px;
      height: 35px;
      margin-top: 4px;
      padding: 0px;
      outline: none;
      font-weight: bold;
    }

    .lkwd-light-btn{
      background-color: #00AF97;
      color: #001d2b;
    }
    
    .lkwd-dark-btn{
      background-color: transparent;
      border: 1px solid #00AF97 !important;
      color: #00AF97;
    }

    .lkwd-remove-btn {
      background-color: transparent;
      border: 1px solid #E46C6C !important;
      color:  #E46C6C;
      font-weight: regular;
    }
    `
  ]
})
export class LkwdButtonComponent implements OnInit {

  @Input() text:string = '';
  @Input() isDark:boolean = false;
  @Input() isRemove:boolean = false;
  @Input() isSmall:boolean = false;
  @Input() isLarge:boolean = false;
  @Output() btnClick:EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  clicked() { this.btnClick.emit(); }

  ngOnInit(): void {
  }

}
