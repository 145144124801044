
<div class="fluid-container lkwd-header-container">
    <div *ngIf="backButton !== ''" class="d-flex mb-3">
        <a [routerLink]="" (click)="onGoBack()" style="color: white;opacity: 60%;;">
            <img src="assets/img/back.png" style="margin-top: -1px;">
            {{backButton}}
        </a>
    </div>
    <div class="d-flex mb-2">
        <div class="p-2"><span class="lkwd-header-text"> {{ headerName }} </span></div>
        <div class="ml-auto p-2">
            <lkwd-button [text]="buttonText" [isDark]="isDarkBtn" [isRemove]="removeStyle" (btnClick)="onButtonClicked()">

            </lkwd-button>
        </div>
    </div>
    <ng-content>
        <!-- CONTENT COMES HERE -->
    </ng-content>
</div>