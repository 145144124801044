import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Modal } from '../models/modals/modal';
import { ModalImageType } from '../models/modals/modal-base';
import { AccountService } from '../services/account.service';
import { LoadingService } from '../services/common/loading.service';
import { ModalService } from '../services/common/modal.service';
import { TermsService } from '../services/terms/terms.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    private termsService: TermsService, 
    private loadingService:LoadingService, 
    private modalService:ModalService,
    private accountService:AccountService) { }

  public editMode:boolean = false;
  public terms:string = '';
  public canEdit = true;

  ngOnInit(): void {
    if(this.accountService.userValue.permissions != null) {
      this.canEdit = this.accountService.userValue.permissions.termsEdit;
    }
    this.loadTerms();
  }

  private loadTerms() {
    this.loadingService.setLoading();
    this.termsService.terms()
    .pipe(first())
    .subscribe({
      next: (_terms) => {
        this.terms = _terms.terms;

        this.editMode = false;
        this.loadingService.setLoading(false);
      },
      error: error => {
        console.log(error);
      }
    });
  }

  editTerms() {
    this.editMode = true;    
  }

  cancelEdit() {
    this.editMode = false;    
  }

  onUpdateTerms(newTerms:string) {
    this.loadingService.setLoading();
    this.termsService.set(newTerms)
    .pipe(first())
    .subscribe({
      next: (_resp) => {
        this.loadingService.setLoading(false);

        this.modalService.showModal(new Modal ('Termos Atualizados com sucesso!', 
        '',   
        ModalImageType.success));

        this.loadTerms();
      },
      error: error => {
        console.log(error);
        this.loadingService.setLoading(false);
      }
    });
  }
}
