export abstract class ModalBase {
    id: string;
    actionButton: ModalButton;

    constructor(actionButton: ModalButton = null, id:string){
        this.id = id;
        this.actionButton = actionButton;
    }
}

export class ModalButton {
    text: string;
    link: string;

    constructor(init?:Partial<ModalButton>) {
        Object.assign(this, init);
    }
}

export enum ModalImageType{
    success,
    error
}