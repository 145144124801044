import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Client } from 'src/app/models/client';
import { UserBase } from 'src/app/models/user-base';

@Component({
  selector: 'app-lkwd-user-card',
  templateUrl: './lkwd-user-card.component.html',
  styleUrls: ['./lkwd-user-card.component.css']
})
export class LkwdUserCardComponent implements OnInit {

  constructor() { }

  @Input() clients:UserBase[];
  @Output() managePermissions =  new EventEmitter<number>();

  ngOnInit(): void {
  }

  getPhoneNumber(model:Client) {
    return model.phoneDDD + ' '+ model.phoneNumber;
  }

  public formatCPF(cpf){
    if(cpf == null)
    {
      return;
    }
    cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  onManagePermissions(id:number) {
    this.managePermissions.emit(id);
  }

}
