import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportDownload } from 'src/app/models/report-download';


@Component({
  selector: 'app-lkwd-data-table',
  templateUrl: './lkwd-data-table.component.html',
  styleUrls: ['./lkwd-data-table.component.css']
})
export class LkwdDataTableComponent implements OnInit {

  @Input() headers = [];
  @Input() dataTable = [];

  @Input() collectionSize;
  @Input() pageSize;

  @Input() page = 1;
  @Input() hideTable = false;
  
  @Output() changedPage = new EventEmitter<number>();
  @Output() downloadFile = new EventEmitter<ReportDownload>();
  @Output() actionImage = new EventEmitter<number>();

  constructor() { }

  onDownloadReport(reportId:number, fileName:string) {
    this.downloadFile.emit(new ReportDownload(reportId, fileName));
  }

  onPageChange() {
    this.changedPage.emit(this.page);
  }

  ngOnInit(): void {
  }

  onActionImage(Id:number) {    
    this.actionImage.emit(Id);
  }
}
