<div class="form-group" [formGroup]="form">
    <label>{{inputLabel}}</label>
    <div class="input-group">
        <input #password name="lkwd-field-pass" 
            [type]="hidePassword ? 'password': 'text'" 
            class="form-control  lkwd-pass-medium"   
            formControlName="password"          
            placeholder="Senha"
            [(ngModel)]="value" autocomplete="new-password"
            >
        <div class="input-group-append">
            <div>
                <button mat-flat-button class="lkwd-pass-btn" (click)="hidePassword = !hidePassword" type="button">
                  <img [src]="hidePassword ? 'assets/img/open_eye.png' : 'assets/img/closed_eye.png'">
                </button>
              </div>
        </div>
    </div>
    <div *ngIf="f.password.errors && submitted" class="error-feedback">
        <div *ngIf="f.password.errors.required">Uma senha deve ser inserida!</div>
        <div *ngIf="f.password.errors.pattern">
            <span>Sua senha deve conter:</span>
            <ul>
                <li>Mínimo de 8 caracteres</li>
                <li>Pelo menos 1 letra maiúscula</li>
                <li>Pelo menos 1 letra minúscula</li>
                <li>Pelo menos 1 número</li>
                <li>Pelo menos 1 caracter especial: !@#$%&*-_?</li>
            </ul>
        </div>
    </div>
</div>