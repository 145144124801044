import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AccountService } from '../services/account.service'
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  navigationStructure = [
    { label:'Dashboard', routerLink: '/dashboard', image:'assets/img/dashboard.png', image_active:'assets/img/dashboard_active.png' },
    { label:'Clientes', routerLink: '/clientes', image:'assets/img/clients.png', image_active:'assets/img/clients_active.png' },
    { label:'Relatórios', routerLink: '/relatorios', image:'assets/img/reports.png', image_active:'assets/img/reports_active.png' },
    { label:'Grupos Familiares', routerLink: '/grupos-familiares', image:'assets/img/family_group.png', image_active:'assets/img/family_group_active.png' },
    { label:'Funcionários', routerLink: '/funcionarios', image:'assets/img/employee.png', image_active:'assets/img/employee_active.png' },
    { label:'Termos e política', routerLink: '/termos-e-politica', image:'assets/img/terms.png', image_active:'assets/img/terms_active.png' },
  ];

  navigationClicked: number = 0;
  logoutClicked: boolean;
  accountClicked: boolean;

  onItemClicked(clicked: number): void {
    this.navigationClicked = clicked;
    this.logoutClicked = false;
    this.accountClicked = false;
  }

  onLogout(): void {
    this.logoutClicked = true;
    this.navigationClicked = null;
    this.accountService.logout();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private router: Router
    ) {}

    ngOnInit () {
      this.router.events.subscribe((val) => {
        if(val instanceof NavigationEnd) {
          let index = this.navigationStructure.findIndex(x => val.url.includes(x.routerLink));
          if(index == -1 && val.url === '/minha-conta') {
            this.accountClicked = true;
            this.navigationClicked = null;
          } else {
            this.navigationClicked = index;
          }          
        }
      });
    }
}
