import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modal } from 'src/app/models/modals/modal';
import { ModalImageType } from 'src/app/models/modals/modal-base';

@Component({
  selector: 'app-lkwd-notification',
  templateUrl: './lkwd-notification.component.html',
  //styleUrls: ['./lkwd-notification.component.css']
})
export class LkwdNotificationComponent implements OnInit {

  @Input() currentModal: Modal;
  @Output() closeModal:EventEmitter<any> = new EventEmitter();

  public modalImage:string = undefined;

  constructor() { }

  ngOnInit(): void {
    if(this.currentModal.image == ModalImageType.success)
    {
      this.modalImage = 'assets/img/check.png';
    } 
    else if(this.currentModal.image == ModalImageType.error) 
    {
      this.modalImage = 'assets/img/trash_big.png';
    }
  }

  onCloseModal() {
    this.closeModal.emit();
  }
}
