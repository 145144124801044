<div class="dropzone" *ngIf="!isFileSet">
    <input type="file" #fileUpload id="fileUpload" name="fileUpload"  (change)="onChooseFile(fileUpload.files)" class="input-file" accept="application/pdf"/>
    <br>
    <img src="assets/img/upload.png">
    <h3><span class="dropzone-description">Selecione</span> ou arraste um arquivo</h3>
</div>

<div *ngIf="isFileSet" style="width: 350px">
    <img src="assets/img/pdf.png">
    <span style="color: white;margin-left: 8px;">{{fileName}}</span>
    <a href="javascript:" (click)="onRemoveFile()" style="float: right;">
        <img src="assets/img/trash.png">
    </a>    
    <hr>
</div>