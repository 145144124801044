import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Analytics } from '../../models/analytics';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private analyticsSubject: BehaviorSubject<Analytics>;
  public analytics: Observable<Analytics>;

  constructor(private http: HttpClient) {
    this.analyticsSubject = new BehaviorSubject<Analytics>(null);
   }

  getAnalytics(dataRange: number){
    let _params = new HttpParams({
      fromObject: {
        range: dataRange.toString()
      }
    });
    return this.http.get<Analytics>(environment.apiUrl + '/analytics', { params: _params })
        .pipe(map(_analytics => {
            this.analyticsSubject.next(_analytics);
            return _analytics;
        }));
  }
}
