<button type="button" class="close" aria-label="Close" >
    <span aria-hidden="true" style="color: white;" (click)="onCloseModal()">&times;</span>
    </button>
<div class="d-flex justify-content-center mt-3">
    <p style="font-size: large;">Gerenciar Permissões</p>
</div>   
<div class="d-flex justify-content-center mt-1">
    <p style="font-size: medium;">Permitir que {{memberName}} acesse os relatórios de:</p>
</div>

<div class="d-flex justify-content-center mt-4"></div>

<div  *ngFor="let m of members"  style="margin: 0px 60px 0px 60px;">
    <div class="row" style="margin-top:10px;">
        <div class="col-sm-6">
            <div class="d-flex justify-content-start">
                <p>{{m.user.name}}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="d-flex justify-content-end">
                <mat-checkbox [checked]="m.isReader" (change)="onAllowReader($event.checked, m.id)"></mat-checkbox>
            </div> 
        </div>
    </div>
    <hr style="margin-top: -5px;">
</div>   



<div class="d-flex justify-content-center mt-5">
    <button  class="btn-type1" mat-flat-button color="primary" (click)="onSavePermissions()">Salvar</button>            
</div> 
<div class="d-flex justify-content-center mt-3 mb-5">
    <button  class="lkwd-header-btn2" mat-flat-button color="primary" (click)="onCloseModal()">Cancelar</button>
</div>