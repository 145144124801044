<div class="container-fluid" class="lkwd-user-card" *ngFor="let client of clients">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-4">
        <span class="lkwd-label">Nome completo: </span> <span class="lkwd-text">{{client?.name}}</span>
      </div>
      <div class="col-sm-6 col-md-7 col-lg-5">
        <span class="lkwd-label">Email: </span> <span class="lkwd-text">{{client?.email}}</span>
      </div>
      <div class="col-sm-6 col-md-5 col-lg-3">
        <span class="lkwd-label">Celular: </span> <span class="lkwd-text">{{getPhoneNumber(client)}}</span>
    </div>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4">
          <span class="lkwd-label"  >CPF: </span> <span class="lkwd-text">{{formatCPF(client?.cpf)}}</span>
        </div>
        <div class="col-sm-6 col-md-7 col-lg-5" *ngIf="managePermissions.observers.length > 0">
          <span class="lkwd-label">Grupo econômico: </span> <span class="lkwd-text">Familiar</span>
        </div>
        <div class="col-sm-6 col-md-5 col-lg-3" *ngIf="managePermissions.observers.length > 0">
              <button type="button" class="btn clear-filter-btn" style="margin-top: 0;    border: 1px solid #00AF97 !important;" (click)="onManagePermissions(client.id)">Gerenciar permissões</button>
        </div>
        <div class="col-sm-6 col-md-7 col-lg-5" *ngIf="managePermissions.observers.length == 0">
          <span class="lkwd-label">Apelido: </span> <span class="lkwd-text">{{client?.alias}}</span>
        </div>
      </div>
</div>