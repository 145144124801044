import { ModalBase, ModalButton } from './modal-base';

export class ModalCreateFamily extends ModalBase {
    public addFamily:boolean;
    public familyId:number;

    constructor(actionButton: ModalButton, addFamily:boolean, familyId:number = undefined, modalId:string = 'default-modal'){        
        super(actionButton, modalId);
        this.addFamily = addFamily;
        this.familyId = familyId;
    }
}