import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  private termsSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.termsSubject = new BehaviorSubject<any>(null);
  }

  terms(){
    return this.http.get<any>(environment.apiUrl + '/terms')
    .pipe(map(_reports => {        
        this.termsSubject.next(_reports);
        return _reports;
    }));
  }

  set(newTerms: string) {
    return this.http.post(environment.apiUrl + '/terms', { newTerms })
    .pipe(map(_resp => {        
      this.termsSubject.next(null);
      return _resp;
    }));
  }
}
