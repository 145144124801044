import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './helpers/auth.guard'
import { TermsComponent } from './terms/terms.component'
import { MyAccountComponent } from './my-account/my-account.component'

const LoginModule = () => import('./login/login.module').then(x => x.LoginModule);
const ClientsModule = () => import('./clients/clients.module').then(x => x.ClientsModule);
const ReportsModule = () => import('./reports/reports.module').then(x => x.ReportsModule);
const EmployeesModule = () => import('./employees/employees.module').then(x => x.EmployeesModule);
const FamilyGroupsModule = () => import('./family-groups/family-groups.module').then(x => x.FamilyGroupsModule);

const routes: Routes = [
  { path: 'login', loadChildren: LoginModule},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
  { path: 'clientes', loadChildren: ClientsModule, canActivate: [AuthGuard] },
  { path: 'relatorios', loadChildren: ReportsModule, canActivate: [AuthGuard] },
  { path: 'grupos-familiares', loadChildren: FamilyGroupsModule, canActivate: [AuthGuard] },
  { path: 'funcionarios', loadChildren: EmployeesModule, canActivate: [AuthGuard] },
  { path: 'termos-e-politica', component: TermsComponent, canActivate: [AuthGuard] },
  { path: 'minha-conta', component: MyAccountComponent, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
