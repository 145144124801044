<button type="button" class="close" aria-label="Close" >
    <span aria-hidden="true" style="color: white;" (click)="onCloseModal()">&times;</span>
    </button>
<div class="d-flex justify-content-center mt-3">
    <div class="d-flex justify-content-center">
        <p style="font-size: large;">Enviar relatório</p>
    </div>  
</div>        
<div class="d-flex justify-content-center mt-3">
    <div style="width: 350px;border:1px">
        <label for="lkwd-client" class="lkwd-input-label label-field" >Selecione um cliente</label>            
    </div>            
</div>   
<div class="d-flex justify-content-center">
    <input id="lkwd-client" type="text" class="form-control lkwd-input-content input-field"  [(ngModel)]="clientModel" [ngbTypeahead]="searchClient"/>            
</div>          
<div class="d-flex justify-content-center mt-3">
    <div style="width: 350px;border:1px">
        <label for="lkwd-type" class="lkwd-input-label label-field">Tipo de relatório</label>            
    </div>            
</div>   
<div class="d-flex justify-content-center">
    <input id="lkwd-type" type="text" class="form-control lkwd-input-content input-field"  [(ngModel)]="typeModel" [ngbTypeahead]="searchType"/>            
</div>  
<div class="d-flex justify-content-center mt-3">
    <div style="width: 350px;border:1px">
        <label for="lkwd-date" class="lkwd-input-label label-field">Data do relatório</label>            
    </div>            
</div>   
<div class="d-flex justify-content-center">
    <input id="lkwd-date" type="text" class="form-control lkwd-input-content input-field"  [(ngModel)]="dateModel" [ngbTypeahead]="searchDate"/>
    <!-- [(ngModel)]="cpfModel" (ngModelChange)="onCPFFilterChange($event)" [ngbTypeahead]="searchCPF" -->
</div>        
<div class="d-flex justify-content-center mt-3">
    <app-drop-zone (onFileChosen)="onChooseFile($event)"></app-drop-zone>
</div>        
<div class="d-flex justify-content-center mt-3" *ngIf="isUploading">
    <div class="progress" style="width: 350px;">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style]="progress" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
</div>        
<div class="d-flex justify-content-center mb-5 mt-3" *ngIf="currentModal.actionButton">
    <button  class="btn-type1" mat-flat-button color="primary" (click)="onUploadReport()">{{currentModal.actionButton.text}}</button>            
</div>  