import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { AnalyticsHistory } from '../../../models/analytics-history'

@Component({
  selector: 'app-lkwd-line-chart',
  templateUrl: './lkwd-line-chart.component.html'
})
export class LkwdLineChartComponent implements OnInit {
  @Input() chartData: AnalyticsHistory[] = [];

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    legend: { position: 'bottom' },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: { fontColor: '#A8A8A8' },
        gridLines: { color: '#001d2b' }
      }],
      yAxes: [{   
        offset: true,     
        ticks: { fontColor: '#A8A8A8', min: 0, stepSize: 1, minRotation: 25 },
        gridLines: { color: '#001d2b' }
      }]
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#00AF97'
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor() { }

  ngOnInit() {
    this.chartData.map(x => x.item1)
    this.lineChartData = [
      { data: this.chartData.map(x => x.item2), label: 'Acessos' },
    ];
    this.lineChartLabels = this.chartData.map(x => x.item1);
  }
}
