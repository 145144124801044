import { ModalBase, ModalButton, ModalImageType } from './modal-base';

export class Modal extends ModalBase{
    // id: string;
    main_message: string;
    message_description: string;
    image: ModalImageType;
    // actionButton: ModalButton;    

    constructor(
        main_message: string,
        message_description: string,
        image: ModalImageType,
        actionButton: ModalButton = null,
        id:string = 'default-modal') {
            super(actionButton,id);
            //base.id = id;
            this.main_message = main_message;
            this.message_description = message_description;
            this.image = image;
            this.actionButton = actionButton;
        }
}
