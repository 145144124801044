<ng-template #uploadModal let-modal >    
    <div class="modal-body lkwd-modal">
        <app-lkwd-upload-report 
            [currentModal]="currentModal" 
            (closeModal)="onCloseModal($event)">
        </app-lkwd-upload-report>
    </div>
</ng-template>

<ng-template #defaultModal let-modal >    
    <div class="modal-body lkwd-modal">
        <app-lkwd-notification 
            [currentModal]="currentModal" 
            (closeModal)="onCloseModal($event)">
        </app-lkwd-notification>
    </div>
</ng-template>

<ng-template #permissionModal let-modal >    
    <div class="modal-body lkwd-modal">
        <app-lkwd-member-permissions  
            [currentModal]="currentModal" 
            (closeModal)="onCloseModal($event)">
        </app-lkwd-member-permissions>
    </div>
</ng-template>

<ng-template #familyModal let-modal >    
    <div class="modal-body lkwd-modal">
        <app-lkwd-create-family  
            [currentModal]="currentModal" 
            (closeModal)="onCloseModal($event)">
        </app-lkwd-create-family>
    </div>
</ng-template>