<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
   
      <div style="text-align: center;">
        <img width="200" src="assets/img/logo.png">
      </div>
    <mat-nav-list>
      <a *ngFor="let nav of navigationStructure;  let i = index" 
      mat-list-item [routerLink]="nav.routerLink" 
      (click)="onItemClicked(i)" 
      [ngClass]="navigationClicked === i ? 'nav-link-active' : 'nav-link' ">
        <img [src]="navigationClicked === i ? nav.image_active : nav.image" class="image-icon">
        {{nav.label}}
      </a>
      <br>
      <a mat-list-item class="nav-link" routerLink="/minha-conta"
      [ngClass]="accountClicked? 'nav-link-active' : 'nav-link' ">
        <img [src]="accountClicked ? 'assets/img/account_active.png': 'assets/img/account.png'" class="image-icon">   
        Minha Conta
      </a>
      <a mat-list-item class="nav-link" (click)="onLogout()" 
      [ngClass]="logoutClicked? 'nav-link-active' : 'nav-link' ">
        <img [src]="logoutClicked ? 'assets/img/logout_active.png': 'assets/img/logout.png'" class="image-icon">   
        Logout
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <button  (click)="drawer.toggle()" 
    *ngIf="isHandset$ | async" mat-mini-fab 
    style="background: #002A3E">
      <mat-icon>menu</mat-icon>
    </button>
    <ng-content *ngIf="!logoutClicked">
           
    </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
