import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { UserRole } from 'src/app/models/user';
import { UserBase } from 'src/app/models/user-base';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.css']
})
export class AddEditUserComponent implements OnInit {

  public headerName:string;
  public backbuttonText:string;
  public ActionButtonName:string = 'Cadastrar';

  @Output() registerClicked = new EventEmitter<UserBase>();
  @Input() model:UserBase;

  public notification = 'email';
  public fullPhone;

  @Input() isEdit:boolean = false;
  public isEmployee:boolean = false;

  constructor(private _location: Location) { }

  ngOnInit(): void {
    this.isEmployee = this.model.userRole == UserRole.Employee;
    if(this.model.userRole == UserRole.Client) {
      if(!this.isEdit) {
        this.headerName = 'Cadastrar Cliente';
        this.backbuttonText = 'Clientes';
      } else {
        this.ActionButtonName = 'Salvar';
        this.headerName = 'Editar informações';
        this.backbuttonText = this.model.name;
        this.notification = this.model.notificationThroughEmail ? 'email' : 'sms';
        this.fullPhone = this.model.phoneDDD + this.model.phoneNumber;
      }
    } else if(this.model.userRole == UserRole.Employee) {
      if(!this.isEdit) {
        this.headerName = 'Cadastrar Funcionário';
        this.backbuttonText = 'Funcionários';
      } else {
        this.headerName = 'Cadastrar Funcionário';
        this.backbuttonText = 'Funcionários';        
        this.notification = this.model.notificationThroughEmail ? 'email' : 'sms';
        this.fullPhone = this.model.phoneDDD + this.model.phoneNumber;
      }
    }
  }

  onClick() {
    this.model.phoneDDD = this.fullPhone.substr(0,2).replace(/\s+/, "");
    this.model.phoneNumber = this.fullPhone.substr(2,this.fullPhone.length-1).replace(/\s+/, "");
    this.model.notificationThroughEmail = this.notification == 'email';
    this.registerClicked.emit(this.model);
  }

  onGoBack() {
    this._location.back();
  }
}
