import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LkwdDoughnutComponent } from './components/lkwd-doughnut/lkwd-doughnut.component';
import { LkwdLineChartComponent } from './components/lkwd-line-chart/lkwd-line-chart.component';
import { LkwdHeaderComponent } from './components/lkwd-header/lkwd-header.component';
import { LkwdChartHeaderComponent } from './components/lkwd-chart-header/lkwd-chart-header.component'
import { LkwdDataTableComponent } from './components/lkwd-data-table/lkwd-data-table.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from "@angular/material/icon";


import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChartsModule } from 'ng2-charts';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { AddEditUserComponent } from './components/add-edit-user/add-edit-user.component';
import { NgxMaskModule } from 'ngx-mask';
import { LkwdModalComponent } from './components/modals/lkwd-modal/lkwd-modal.component';
import { LkwdReportsTableComponent } from './components/lkwd-reports-table/lkwd-reports-table.component';
import { LkwdDateRangeComponent } from './components/lkwd-date-range/lkwd-date-range.component'
import { DateRangePickerModule } from '@uiowa/date-range-picker';
import { LkwdLoadingComponent } from './components/lkwd-loading/lkwd-loading.component';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LkwdUserCardComponent } from './components/lkwd-user-card/lkwd-user-card.component';
import { LkwdUploadReportComponent } from './components/modals/lkwd-upload-report/lkwd-upload-report.component';
import { LkwdNotificationComponent } from './components/modals/lkwd-notification/lkwd-notification.component';
import { LkwdMemberPermissionsComponent } from './components/modals/lkwd-member-permissions/lkwd-member-permissions.component';
import { LkwdCreateFamilyComponent } from './components/modals/lkwd-create-family/lkwd-create-family.component';
import { LkwdButtonComponent } from './components/forms/lkwd-button/lkwd-button.component';
import { LkwdPassInputComponent } from './components/forms/lkwd-pass-input/lkwd-pass-input.component';
import { LkwdInputFilterComponent } from './components/forms/lkwd-input-filter/lkwd-input-filter.component';
import { LkwdEmptyStateComponent } from './components/lkwd-empty-state/lkwd-empty-state.component';


@NgModule({
  declarations: [
    LkwdDoughnutComponent,
    LkwdLineChartComponent,
    LkwdHeaderComponent,
    LkwdChartHeaderComponent,
    LkwdDataTableComponent,
    AddEditUserComponent,
    LkwdModalComponent,
    LkwdReportsTableComponent,
    LkwdDateRangeComponent,
    LkwdLoadingComponent,
    DropZoneComponent,
    LayoutComponent,
    LkwdUserCardComponent,
    LkwdUploadReportComponent,
    LkwdNotificationComponent,
    LkwdMemberPermissionsComponent,
    LkwdCreateFamilyComponent,
    LkwdButtonComponent,
    LkwdPassInputComponent,
    LkwdInputFilterComponent,
    LkwdEmptyStateComponent
  ],
  imports: [
    MatIconModule,
    MatChipsModule,
    MatCheckboxModule,
    DateRangePickerModule,
    NgxMaskModule.forRoot(),
    MatRadioModule,
    NgxLoadingModule,
    RouterModule,
    NgbModule,
    MatButtonModule,
    CommonModule,
    ChartsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NgxMaskModule,
    AddEditUserComponent,
    LkwdDoughnutComponent,
    LkwdLineChartComponent,
    LkwdHeaderComponent,
    LkwdChartHeaderComponent,
    LkwdDataTableComponent,
    LkwdModalComponent,
    LkwdReportsTableComponent,
    LkwdLoadingComponent,
    LayoutComponent,
    LkwdUserCardComponent,
    
    LkwdButtonComponent,
    LkwdPassInputComponent,
    LkwdInputFilterComponent,
    LkwdEmptyStateComponent
  ]
})
export class SharedModuleModule { }
