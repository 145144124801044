import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/common/loading.service';

@Component({
  selector: 'app-lkwd-loading',
  templateUrl: './lkwd-loading.component.html'
})
export class LkwdLoadingComponent implements OnInit {

  public loading:boolean = false;

  constructor(private loadingService:LoadingService) { }

  ngOnInit(): void {
    this.loadingService.onShowLoading()
    .subscribe({
      next: (_show) => {
        this.loading = _show;
      },
      error: error => {
        console.log(error);
      }
    })
  }
}
