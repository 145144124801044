<ng-container
  *ngIf="isLoggedIn; then loggedIn; else loggedOut">
</ng-container>

<app-lkwd-modal></app-lkwd-modal>
<app-lkwd-loading></app-lkwd-loading>

<ng-template #loggedIn>
  <app-navigation>    
    <router-outlet></router-outlet>
  </app-navigation>
</ng-template>

<ng-template #loggedOut>
  <router-outlet></router-outlet>
</ng-template>