import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lkwd-chart-header',
  templateUrl: './lkwd-chart-header.component.html',
  styleUrls: ['./lkwd-chart-header.component.css']
})
export class LkwdChartHeaderComponent implements OnInit {

  @Input() selectOptions: string[] = [];
  @Input() title: string = '';
  @Output() export = new EventEmitter();
  @Output() changeDataRange = new EventEmitter<string>();

  selectedOption = '';

  constructor() { }

  ngOnInit(): void {
    this.selectedOption = this.selectOptions[0];
  }

  public exportData(){
    this.export.emit(null);
  }

  public onChangeDataRange({value}) {
    this.changeDataRange.emit(value);
  }
}
