import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ModalUpload } from 'src/app/models/modals/modal-upload';
import { ReportType } from 'src/app/models/report-type';
import { ModalService } from 'src/app/services/common/modal.service';
import { ReportService } from 'src/app/services/reports/report.service';

@Component({
  selector: 'app-lkwd-upload-report',
  templateUrl: './lkwd-upload-report.component.html',
  styleUrls: ['./lkwd-upload-report.component.css']
})
export class LkwdUploadReportComponent implements OnInit {

  @Input() currentModal: ModalUpload;
  @Output() closeModal:EventEmitter<any> = new EventEmitter();

  public progress = 'width: 0%';

  private currentFile: File;
  public isUploading:boolean = false;
  public typeModel: any;

  private reportDatesFilter: any[];
  public dateModel: any;

  private FilterTypeText(list:string[], typed:string) {
    return list.filter(v => v.toLowerCase()
    .indexOf(typed.toLowerCase()) > -1)
    .slice(0, 10);
  }

  searchDate = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? 
      this.reportDatesFilter.map(x => x.name).splice(0,11): this.FilterTypeText(this.reportDatesFilter.map(x => x.name), term))
  );

  private typesFilter: any[];
  searchType = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.typesFilter.map(x => x.name))
  );

  public clientModel: any;
  private clientsFilter:any[];
  searchClient = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? 
      this.clientsFilter.map(x => x.name): this.FilterTypeText(this.clientsFilter.map(x => x.name), term))
  );

  
  constructor(private reportService:ReportService,
    private modalService: NgbModal, 
    private lkwdModalService: ModalService, ) { }

  ngOnInit(): void {
    console.log(this.currentModal);
    this.reportService.getFilterOptions()
    .subscribe(
      response => {
        this.reportDatesFilter = response.reportDates;
        this.clientsFilter = response.clients;
        this.typesFilter = response.types;
      },
      error => {
        console.log(error);
      }
    );
  }

  setProgressBar(currentProgress:number = 0, limitProgress:boolean = true) {
    if(limitProgress && currentProgress > 80) {
      currentProgress = 80;
    }
    this.progress = 'width: ' + currentProgress + '%';
  }

  onChooseFile(file: File) {
    this.currentFile = file;
  }

  onUploadReport() {
    if(this.currentFile != undefined && this.typeModel != undefined && this.dateModel != undefined && this.clientModel != undefined) {
      
      this.isUploading = true;
      console.log(this.currentFile);

      let r_date = this.reportDatesFilter.find(x => x.name == this.dateModel).date;
      
      let r_type_obj = this.typesFilter.find(x => x.name == this.typeModel);
      let r_isconsolidated = r_type_obj.isConsolidated;
      let r_type = r_type_obj.id as ReportType;

      let r_client_id = this.clientsFilter.find(x => x.name == this.clientModel).id;

      this.reportService.getUploadSignedUrl(r_client_id, r_isconsolidated, r_type)
      .subscribe(
        signedUrlResponse => {
          this.setProgressBar(30, false);
          this.reportService.uploadToS3(signedUrlResponse.signedUrl, this.currentFile)
          .subscribe(
            awsResponse => {
              this.setProgressBar(75, false);
              this.reportService.upload(r_client_id, r_isconsolidated, r_type, r_date, signedUrlResponse.reportKey, this.currentFile.name)
              .subscribe(
                beResponse => {
                  {
                    this.setProgressBar(100, false);
                    this.modalService.dismissAll();
                    this.isUploading = false;
                    this.lkwdModalService.closeModal(this.currentModal);
                    this.closeModal.emit();
                  }
                },
                beError => {
                  console.log(beError);
                }
              );                        
            },
            awsError => {
              console.log(awsError);
            }
          );
        },
        error => {
          console.log(error);
        }
      );

    }
  }

  onCloseModal() {
    this.closeModal.emit();
  }
}
