<div class="fluid-container">
    <div class="d-flex justify-content-center" style="margin-top: 10%;" *ngIf="imageScreen != ''">
        <img [src]="imageScreen">
    </div>
    <div class="d-flex justify-content-center mt-4" *ngIf="title != ''">
        <span style="color: white; opacity: 87%;font-size:x-large;">{{title}}</span>
    </div>
    <div class="d-flex justify-content-center mt-3" *ngIf="description != ''">
        <span style="color: white; opacity: 60%;font-size:large;">{{description}}</span>
    </div>
    <div class="d-flex justify-content-center mt-4" *ngIf="btnText != ''">
        <lkwd-button [text]="btnText" [isLarge]="true" (btnClick)="onButtonClicked($event)"></lkwd-button>
    </div>
</div>
