<button type="button" class="close" aria-label="Close" >
    <span aria-hidden="true" style="color: white;" (click)="onCloseModal()">&times;</span>
  </button>
<div class="d-flex justify-content-center mt-5">
    <img [src]="modalImage">
</div>
<div class="d-flex justify-content-center mt-5">
    <p style="font-size: large;">{{currentModal?.main_message}}</p>
</div>   
<div class="d-flex justify-content-center mt-1">
    <p>{{currentModal?.message_description}}</p>
</div>
<div class="d-flex justify-content-center mt-3" *ngIf="currentModal?.actionButton">
    <button  class="btn-type1" mat-flat-button color="primary" [routerLink]='currentModal.actionButton.link' (click)="onCloseModal()">{{currentModal?.actionButton?.text}}</button>            
</div>                
<div class="d-flex justify-content-center mt-3 mb-5">
    <button  class="lkwd-header-btn2" mat-flat-button color="primary" (click)="onCloseModal()">Fechar</button>
</div>