import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from './services/account.service'
import { User } from './models/user'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  isLoggedIn: boolean = true;
  user: User;

  title = 'lakewood-web-manager';

  constructor(private accountService: AccountService) {    
  }

  ngOnInit() {
    this.accountService.user.subscribe((user) => {
      this.isLoggedIn = user !== null;
      this.user = user;
    });
  }
}
