import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportsPaginated } from 'src/app/models/reports-paginated';
import { ReportType } from 'src/app/models/report-type';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Report } from 'src/app/models/report';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private reportDeleteSubject: BehaviorSubject<boolean>;
  private reportSubject: BehaviorSubject<ReportsPaginated>;

  constructor(private http: HttpClient) {
    this.reportSubject = new BehaviorSubject<ReportsPaginated>(null);
    this.reportDeleteSubject = new BehaviorSubject<boolean>(null);
  }

  getDownloadSignedUrl(reportId: number) {
    return this.http.get<any>(environment.apiUrl + '/reports/download-signed-url/' + reportId.toString());
  }

  getUploadSignedUrl(userId, isConsolidate, type) {
    let _params = new HttpParams({
      fromObject: {
        userId: userId,
        type: type,
        isConsolidated: isConsolidate
      }
    });
    return this.http.get<any>(environment.apiUrl + '/reports/upload-signed-url', { params: _params });
  }

  uploadToS3(signedUrl: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.put(signedUrl, formData);
  }

  donwload(url: string){
    let headers = new HttpHeaders({'Accept': 'application/pdf' });
    return this.http.get<any>(url, { responseType: 'blob' as 'json', headers });
  }

  getFilterOptions(clientsWithReport:boolean = undefined, reportSenders:boolean = undefined) {
    let getParams = {};
    if(clientsWithReport != undefined) {
      getParams['clientsWithReport'] = clientsWithReport;
    }
    if(reportSenders != undefined) {
      getParams['reportSenders'] = reportSenders;
    }
    return this.http.get<any>(environment.apiUrl + '/reports/report-filters', 
    { params: new HttpParams({fromObject : getParams}) });
  }


  private formatDate(date:Date):string {
    if(date != null) {
      return (date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate());
    }    
    return date?.toString();     
  }

  upload(userId, isConsolidated: string, type, reportDate, key, fileName){
    return this.http.post<any>(environment.apiUrl + '/reports/upload-report', {
      userId,
      isConsolidated,
      type,
      reportDate,
      key,
      fileName
    });
  }

  remove(reportId: number){
    return this.http.delete<any>(environment.apiUrl + '/reports/' + reportId.toString())
    .pipe(map(resp => {
      this.reportDeleteSubject.next(true);
      return resp;
    }))
  }

  paginated(pageNumber: number, pageSize: number,
    clientId:number = null,
    type:ReportType = null,
    startDate: Date = null,
    endDate: Date = null,
    isConsolidated:boolean = null,
    read:boolean = null,
    sentBy:number = null) {
      let _params = new HttpParams({
        fromObject: {
          pageNumber: pageNumber.toString() || '',
          pageSize: pageSize.toString() || '',
          clientId: clientId?.toString() || '',
          type: type?.toString() || '',
          startDate: this.formatDate(startDate) || '',
          endDate: this.formatDate(endDate) || '',
          isConsolidated: isConsolidated?.toString() || '',
          read: read?.toString() || '',
          sentBy: sentBy?.toString() || ''
        }
      });
      return this.http.get<ReportsPaginated>(environment.apiUrl + '/reports', { params: _params })
      .pipe(map(_reports => {     
          this.reportSubject.next(_reports);
          return _reports;
      }));
  }
}
