import { UserRole } from './user';

export abstract class UserBase {
    public id: number;
    public name: string;
    public cpf: string;
    public email: string;
    public phoneDDD: string;
    public phoneNumber: string;
    public userRole: UserRole;
    public alias:string;
    public notificationThroughEmail:boolean;

    constructor(userRole:UserRole) {
        this.userRole = userRole;
    }
}