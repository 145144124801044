import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@uiowa/date-range-picker';

@Component({
  selector: 'app-lkwd-date-range',
  templateUrl: './lkwd-date-range.component.html',
  styleUrls: ['./lkwd-date-range.component.css']
})
export class LkwdDateRangeComponent implements OnInit {
  @Input() dateRange = new DateRange();
  @Output() rangeChange = new EventEmitter<DateRange>();
  maxDate = new Date();

  ngOnInit(): void {
    this.maxDate.setDate(this.maxDate.getDate() + 20);
  }

  changed(event:DateRange) { this.rangeChange.emit(event) }
}
