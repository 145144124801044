<div *ngIf="showAddFamily; then addFamily else addMember"></div>
<ng-template #addFamily let-modal >    
    <button type="button" class="close" aria-label="Close" >
        <span aria-hidden="true" style="color: white;" (click)="onCloseModal()">&times;</span>
        </button>
    <div class="d-flex justify-content-center mt-3">
        <p style="font-size: large;">Criar grupo familiar</p>
    </div>
    
    <div class="d-flex justify-content-center mt-4"></div>
    <div class="container-fluid" style="max-width: 344px;">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0;padding-right: 0;">
                <label for="family-name" class="lkwd-input-label">Nome da família</label>            
                <input id="family-name" type="text" class="form-control lkwd-input-content" placeholder="Família" [(ngModel)]="familyName"/>      
                <span style="font-size: small;color: #E46C6C;" *ngIf="displayFamilyNameError">Forneça um nome para a família</span>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-4"></div>
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0;padding-right: 0;">
                <label for="typeahead-basic" class="lkwd-input-label">Selecionar usuários</label>
                <input ng-keyup="onKeyUp()" id="typeahead-basic" type="text" class="form-control lkwd-input-content" (ngModelChange)="onClientFilterChange($event)" [ngModel]="clientModel" [ngbTypeahead]="searchClient" placeholder="Buscar usuários"/>      
            </div>
        </div>
        <div class="row">
            <div class="d-flex justify-content-center mt-2" style="width: 100%;min-height: 50px;">
                <div style='background-color: #001d2b;padding: 10px;width: 100%;'>
                    <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let client of selectedClients" [selectable]="selectable"
                                    [removable]="removable" (removed)="remove(client)">
                            {{client.name}}
                            <a href="javascript:" (click)="onRemoveUser(client)">
                            <img src="assets/img/remove.png" style="margin-left: 30px;">
                            </a>
                            <!-- <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon> -->
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div> 
        </div>
    </div>
    <div class="d-flex justify-content-center mt-4 mb-5">
        <button  class="btn-type1" mat-flat-button color="primary" (click)="onCreateFamily()">Salvar</button>            
    </div> 
</ng-template>

<ng-template #addMember let-modal >    
    <button type="button" class="close" aria-label="Close" >
        <span aria-hidden="true" style="color: white;" (click)="onCloseModal()">&times;</span>
        </button>
    <div class="d-flex justify-content-center mt-3">
        <p style="font-size: large;">Adicionar membro familiar</p>
    </div>
    <div class="d-flex justify-content-center mt-3"></div>
    <div class="container-fluid" style="max-width: 344px;">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0;padding-right: 0;">
                <label for="typeahead-basic" class="lkwd-input-label">Selecionar usuário</label>
                <input ng-keyup="onKeyUp()" id="typeahead-basic" type="text" class="form-control lkwd-input-content" [(ngModel)]="clientModel" [ngbTypeahead]="searchClient" placeholder="Buscar usuários"/>
                <span style="font-size: small;color: #E46C6C;" *ngIf="displayClientError">Forneça um cliente</span>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-4 mb-5">
        <button  class="btn-type1" mat-flat-button color="primary" (click)="onAddMember()">Adicionar membro</button>            
    </div>
</ng-template>