import { ModalBase, ModalButton } from './modal-base';

export class ModalPermissions extends ModalBase{

    public userId:number;

    constructor(actionButton: ModalButton, userId:number, modalId:string = 'default-modal'){        
        super(actionButton, modalId);
        this.userId = userId;
    }
}