<div class="table-container">
  <div class="loading-container">
    <div class="loading">
      <table class="table table-striped" *ngIf="!hideTable" >
        <thead class="lkwd-table-header">
        <tr>
          <th scope="col" *ngFor="let h of headers">{{h}}</th>          
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let rows of dataTable">
          <td *ngFor="let r of rows">
            <div *ngIf="r.type === 'text'; then textTemplate"></div>
            <ng-template #textTemplate>{{ r.value }}</ng-template>
            <div *ngIf="r.type === 'link'; then linkTemplate"></div>
            <ng-template #linkTemplate><a class="lkwd-link" [routerLink]="r.link" >{{ r.value }}</a></ng-template>
            <div *ngIf="r.type === 'reportFile'; then FileTemplate"></div>
            <ng-template #FileTemplate><a  style="color: white;" href="javascript:" (click)="onDownloadReport(r.Id, r.value)" ><img src="assets/img/pdf.png" style="margin-right: 5px;;">{{ r.value }}</a></ng-template>         
            <div *ngIf="r.type === 'actionImage'; then ActionImage"></div>
            <ng-template #ActionImage>
              <a href="javascript:" (click)="onActionImage(r.Id)" >
                <img src="assets/img/trash.png">
              </a></ng-template>         
          </td>
        </tr>
        </tbody>
      </table>      
      <div class="d-flex justify-content-center m-5" *ngIf="!hideTable">
        <ngb-pagination  class="m=3" style="background-color: transparent;" [maxSize]="2" [rotate]="true" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange()">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>