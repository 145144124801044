<app-lkwd-header
  headerName="Termos e política" 
  [buttonText]="canEdit ? 'Editar Termos' : ''"
  (buttonClicked)="editTerms()">
  <br>
  <br>
  
  <div *ngIf="terms != ''">
    <div *ngIf="editMode; then termsEdit else termsShow"></div>
  </div>


  <!-- EDIT -->

  <ng-template #termsEdit>
    <textarea #newTerms style="background-color: #001d2b;width: 100%;min-height: 500px;padding: 50px;">
      {{terms}}
    </textarea>
    <br>
    <br>
    <div class="row float-right" style="margin-bottom: 20px;;">
      <div class="col-sm-6 col-md-3 col-lg-6">
          <button  class="lkwd-float-right-btn2" mat-flat-button color="primary" (click)="cancelEdit()" >Cancelar</button>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-6">
          <button  class="lkwd-float-right-btn1" mat-flat-button color="primary" (click)="onUpdateTerms(newTerms.value)">Salvar</button>
      </div>
  </div>
  </ng-template>


  <!-- SHOW -->

  <ng-template #termsShow>
    <div class="container-fluid" style="background-color: #001d2b;padding: 50px;">
      <p>{{terms}}</p>
    </div>
  </ng-template>

</app-lkwd-header>