<div *ngIf="isEmpty; then emptyState else content"></div>
<ng-template #emptyState>
  <lkwd-empty-state [screen]="screen" title="Nenhum relatório encontrado" description="Você ainda não enviou nenhum relatório."></lkwd-empty-state>
</ng-template>
<ng-template #content>
  <div class="row mb-4">
    <div class="col-sm-3" *ngIf="this.clientId == null">
      <lkwd-input-filter label="Cliente" [model]="sentToModel" [filterList]="sentToFilterString" (modelChange)="onClientChange($event)" placeholder="Selecione um cliente">
      </lkwd-input-filter>    
    </div>
    <div class="col-sm-2">
      <lkwd-input-filter label="Tipo de relatório" [model]="reportTypeModel" [filterList]="reportTypeFilter" (modelChange)="onReportTypeChange($event)" placeholder="Selecione um tipo">
      </lkwd-input-filter>  
    </div>
    <div class="col-sm-2">
      <lkwd-input-filter label="Enviado Por" [model]="sentByModel" [filterList]="sentByFilterString" (modelChange)="onSenderChange($event)" placeholder="Selecione um funcionário">
      </lkwd-input-filter>   
    </div>
    <div class="col-sm-3">
      <label for="lkwd-family" class="lkwd-input-label">Data</label>
      <app-lkwd-date-range [dateRange]="dateRange" (rangeChange)="onDateRangeChange($event)"></app-lkwd-date-range>
    </div>
    <div class="col-sm-2 d-flex justify-content-start mt-4">
      <lkwd-button text="Limpar busca"  isDark="true" isSmall="true" (btnClick)="onClearSearch()">        
      </lkwd-button>
    </div>      
  </div>
  <app-lkwd-data-table
    [headers]="headers" 
    [dataTable]="dataTable"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    (changedPage)="onPageChanged($event)"
    (downloadFile)="onDownloadFile($event)"
    (actionImage)="onActionImage($event)"
    [hideTable]="hideTable"
    [page]="currentPage">
  </app-lkwd-data-table>
  
</ng-template>



    



