import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/common/modal.service';
import { ModalBase } from 'src/app/models/modals/modal-base';
import { Modal } from 'src/app/models/modals/modal';
import { ModalUpload } from 'src/app/models/modals/modal-upload';
import { ModalPermissions } from 'src/app/models/modals/modal-permissions';
import { ModalCreateFamily } from 'src/app/models/modals/modal-create-family';

@Component({
  selector: 'app-lkwd-modal',
  templateUrl: './lkwd-modal.component.html',
  styleUrls: ['./lkwd-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LkwdModalComponent implements OnInit, OnDestroy {

  public isUpload:boolean = false;
  modalImage = '';
  modals: ModalBase[] = [];
  currentModal: ModalBase;

  @Input() id = 'default-modal';  
  showModalSubscription: Subscription;

  @ViewChild('defaultModal')
  private defaultModal: TemplateRef<any>;

  @ViewChild('uploadModal')
  private uploadModal: TemplateRef<any>;

  @ViewChild('permissionModal')
  private permissionModal: TemplateRef<any>;

  @ViewChild('familyModal')
  private familyModal: TemplateRef<any>;

  constructor(private modalService: NgbModal, private lkwdModalService: ModalService) { }    

  ngOnInit(): void {
    this.showModalSubscription = this.lkwdModalService.onShowModal(this.id)
    .subscribe(modal => {
        this.currentModal = modal;
        if (modal instanceof Modal) {
          this.open(this.defaultModal);
        } 
        else if(modal instanceof ModalUpload) {
          this.open(this.uploadModal);
        }
        else if(modal instanceof ModalPermissions) {

          this.open(this.permissionModal);
        }
        else if(modal instanceof ModalCreateFamily) 
        {
          this.open(this.familyModal);
        }
        this.modals.push(modal); 
    });
  }

  ngOnDestroy() {
    this.showModalSubscription.unsubscribe();
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  onCloseModal() {
    this.modalService.dismissAll();
  }
}
