<button 
    *ngIf="text !== ''"
    [ngClass]="{
        'lkwd-light-btn': !isDark && !isRemove, 
        'lkwd-dark-btn': isDark && !isRemove, 
        'lkwd-remove-btn': isRemove,
        'lkwd-medium-btn': !isSmall && !isLarge,
        'lkwd-small-btn': isSmall && !isLarge,
        'lkwd-large-btn': isLarge
    }" 
    mat-flat-button 
    color="primary" 
    (click)="clicked()">{{text}}
</button>

