import { Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lkwd-header',
  templateUrl: './lkwd-header.component.html',
  styleUrls: ['./lkwd-header.component.css']
})
export class LkwdHeaderComponent implements OnInit {

  @Input() headerName: string;
  @Input() buttonText: string = '';
  @Input() backButton: string = '';
  @Input() removeStyle:boolean = false;
  @Input() isDarkBtn:boolean = false;

  @Output() buttonClicked = new EventEmitter();
  constructor(private _location: Location) { }

  ngOnInit(): void {
  }

  onButtonClicked() {
    this.buttonClicked.emit();
  }

  onGoBack() {
    this._location.back();
  }
}
