import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/models/user';
import { UserBase } from 'src/app/models/user-base';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private _baseUrlService = environment.apiUrl + '/user/';

  constructor(private http: HttpClient) { }

  get(role:UserRole = undefined, hasFamily: boolean = undefined,  userId:number = undefined) {
    let getObj = {};

    if(role != undefined)    
      getObj["role"] = role;
    if(userId != undefined)    
      getObj["userId"] = userId;
    if(hasFamily != undefined)    
      getObj["hasFamily"] = hasFamily;

    return this.http.get<UserBase[]>(this._baseUrlService, { params: new HttpParams({ fromObject: getObj }) })
    .pipe(map(_users => {
        return _users;
    }));
  }

  myAccount() {
    return this.http.get<UserBase>(this._baseUrlService + 'get-my-account')
    .pipe(map(_users => {
        return _users;
    }));
  }

  update(model:UserBase, currentPassword:string = undefined, newPassword:string = undefined){
    if(currentPassword != undefined && newPassword != undefined) {
      model['currentPassword'] = currentPassword;
      model['newPassword'] = newPassword;
    }
    return this.http.put(this._baseUrlService, model)
    .pipe(map(_resp => {
      return _resp;
    }));
  }
}
