<div class="add-edit-view-container">
    <app-lkwd-header headerName="Minha conta"  [buttonText]="isEdit ? '' : 'Editar Perfil'" (buttonClicked)="onEditProfile()">
        <div class="lkwd-container" *ngIf="model.name != ''">
            <form>
                <br>
                <div class="row">
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-fullName">Nome Completo</label>
                            <input [(ngModel)]="model.name" name="lkwd-field-fullName" type="text" class="form-control" id="lkwd-field-fullName" aria-describedby="emailHelp" placeholder="Nome completo" [disabled]="!isEdit">            
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-email">E-mail</label>
                            <input [(ngModel)]="model.email" name="lkwd-field-email" type="text" class="form-control" id="lkwd-field-email" placeholder="E-mail" [disabled]="!isEdit">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-phone">Celular</label>
                            <input type="text" mask="(00) 0 0000-0000" [(ngModel)]="fullPhone"  name="lkwd-field-phone" class="form-control" id="lkwd-field-phone" aria-describedby="emailHelp" placeholder="Celular" [disabled]="!isEdit">                    
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-cpf">CPF</label>
                            <input type="text"  mask="000.000.000-00"  [(ngModel)]="model.cpf" name="lkwd-field-cpf" class="form-control" id="lkwd-field-cpf" placeholder="Cpf" [disabled]="!isEdit">
                        </div>
                    </div>
                </div>
                <div *ngIf="isEdit">
                    <div class="row">
                        <div class="col-sm-12 col-md-5 col-lg-6">
                            <lkwd-pass-input inputLabel="Senha atual" #p1></lkwd-pass-input>
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-6">
                            <lkwd-pass-input inputLabel="Nova senha" #p2></lkwd-pass-input>
                        </div>
                    </div>
                   <hr>
                   <div class="d-flex flex-row-reverse">
                    <div class="p-2">
                        <lkwd-button text="Salvar" (btnClick)="onUpdateUser(p1.value, p2.value)"></lkwd-button>
                    </div>
                    <div class="p-2">
                        <lkwd-button text="Cancelar" isDark="true" (btnClick)="onEditProfile()"></lkwd-button>
                    </div>
                  </div>    
                </div>
      
            </form>
        </div>
    </app-lkwd-header>
</div>
