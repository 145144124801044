import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LkwdScreen } from 'src/app/models/common/lkwd-screen';

@Component({
  selector: 'lkwd-empty-state',
  templateUrl: './lkwd-empty-state.component.html'
})
export class LkwdEmptyStateComponent implements OnInit {

  @Input() btnText:string = '';
  @Input() screen:LkwdScreen;
  @Input() title:string;
  @Input() description:string;

  imageScreen:string;

  @Output() btnclick:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if(this.screen == LkwdScreen.Clients) {
      this.imageScreen = 'assets/img/empty_client.png';
    } 
    else if(this.screen == LkwdScreen.Reports) {
      this.imageScreen = 'assets/img/empty_report.png';
    } 
    else if(this.screen == LkwdScreen.FamilyGroups) {
      this.imageScreen = 'assets/img/empty_group.png';
    } 
    else if(this.screen == LkwdScreen.Employees) {
      this.imageScreen = 'assets/img/empty_employee.png'
    }
  }

  onButtonClicked(event) { this.btnclick.emit(); }
}
