<div class="add-edit-view-container">
    <app-lkwd-header [headerName]="headerName" [backButton]="backbuttonText">
        <div class="lkwd-container">
            <form>
                <div class="row mt-4">
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-fullName">Nome Completo</label>
                            <input [(ngModel)]="model.name" name="lkwd-field-fullName" type="text" class="form-control" id="lkwd-field-fullName"  placeholder="Nome completo">            
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-alias">Apelido</label>
                            <input [(ngModel)]="model.alias" name="lkwd-field-alias" type="text" class="form-control" id="lkwd-field-alias" placeholder="Insira um apelido" [disabled]="isEmployee"  [ngClass]="{'employee-field': isEmployee}">            
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-email">E-mail</label>
                            <input [(ngModel)]="model.email" name="lkwd-field-email" type="text" class="form-control" id="lkwd-field-email" placeholder="E-mail">
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-phone">Celular</label>
                            <input type="text" mask="(00) 0 0000-0000" [(ngModel)]="fullPhone"  name="lkwd-field-phone" class="form-control" id="lkwd-field-phone"  placeholder="Celular">                    
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5 col-lg-6">
                        <div class="form-group">
                            <label for="lkwd-field-cpf">CPF</label>
                            <input type="text"  mask="000.000.000-00"  [(ngModel)]="model.cpf" name="lkwd-field-cpf" class="form-control" id="lkwd-field-cpf" placeholder="Cpf">
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-12">
                        <p>Deseja receber o código de primeiro acesso por e-mail<br> ou celular?</p>            
                    </div>                
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-6">
                        <mat-radio-group name="lalal" [(ngModel)]="notification">
                            <mat-radio-button value="email" style="margin-right: 20px;">Email</mat-radio-button>
                            <mat-radio-button value="sms">Celular</mat-radio-button>
                        </mat-radio-group>
                    </div>                      
                </div>
                <br>
                <hr>
                <div class="row float-right">
                    <div class="col-sm-6 col-md-3 col-lg-6">
                        <button  class="lkwd-header-btn2" mat-flat-button color="primary" (click)="onGoBack()" >Cancelar</button>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-6">
                        <button  class="lkwd-header-btn" mat-flat-button color="primary" (click)="onClick()">{{ActionButtonName}}</button>
                    </div>
                </div>            
            </form>
        </div>    
    </app-lkwd-header>
</div>
