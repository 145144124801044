import { EmployeeListItem } from './employee-list-item';

export class User {
    public token: string;
    public user: {
        id: number;
        name: string;
        email: string;
    }
    public permissions: EmployeeListItem
}


export enum UserRole {
    Client,
    Admin,
    Employee
}