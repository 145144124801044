import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MemberList } from 'src/app/models/member-list';
import { RegisterMemberPermission } from 'src/app/models/requests/register-member-permission';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MembersService {

  private _baseUrlService = environment.apiUrl + '/member/';

  constructor(private http: HttpClient) { }

  getMemberList(userId = undefined, familyId = undefined) {
    let _params = userId != undefined ?
      new HttpParams({ fromObject: { userId } }) :
      new HttpParams({ fromObject: { familyId } });
      return this.http.get<MemberList>(this._baseUrlService, { params: _params })
      .pipe(map(_members => {
          return _members;
      }));
  }

  registerPermission(model:RegisterMemberPermission){
    return this.http.post(this._baseUrlService + 'register-member-permissions', model)
    .pipe(map(_resp => {
      return _resp;
    }));
  }

  // setMemberPermission(memberId:number, readers:number[]) {
  //   let _params = userId != undefined ?
  //     new HttpParams({ fromObject: { userId } }) :
  //     new HttpParams({ fromObject: { familyId } });

  //     return this.http.get<MemberList>(this._baseUrlService, { params: _params })
  //     .pipe(map(_members => {        
  //         console.log(_members);
  //         return _members;
  //     }));
  // }

}
