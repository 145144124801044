import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-lkwd-doughnut',
  templateUrl: './lkwd-doughnut.component.html'
})
export class LkwdDoughnutComponent implements OnInit {

  @Input() chartData = [];
  private total: number = 0;

  public options: any = {
    legend: { position: 'bottom' },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'center',
        clamp: true,
        offset: 20,
        align: 'center',
        display: true,
        padding: 4,
        borderColor: 'lightgray',
        borderWidth: 2,
        backgroundColor: '#E8E8E8',
        formatter: (val) => {
          return (Math.ceil((val * 100)/this.total)) + ' %';
        },
        font: {
          size: 13,
          weight: 'bold'
        }
      }
    }
  };

  public doughnutChartLabels: Label[] = [ 'Tablet', 'APP', 'Web'];
  public doughnutChartData: MultiDataSet = [];
  public doughnutChartPlugins = [pluginDataLabels];

  public donutColors=[
    { 
      backgroundColor: ['#FDCB92', '#FFF29D', '#00AF97'], 
      borderColor: ['#001d2b', '#001d2b', '#001d2b']
    }
  ];

  public doughnutChartType: ChartType = 'doughnut';

  constructor() { }

  ngOnInit() {    
    this.doughnutChartData.push(this.chartData);
    this.total = this.chartData.reduce((acc, current) => acc + current);
  }
}
